import React, { Fragment, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { Option } from "app/js/types";

type SelectModeProps = RouteComponentProps<string> & {
  includeAuto?: boolean;
  includeUserAll?: boolean;
};

const SelectMode: React.FC<SelectModeProps> = function ({
  history,
  location,
  includeAuto = true,
  includeUserAll = false,
}) {
  let options = [{ value: "user_latest", label: "User Latest" }];
  if (includeAuto) {
    options = options.concat([
      { value: "latest", label: "Latest" },
      { value: "auto", label: "Auto" },
    ]);
  }
  if (includeUserAll) {
    options = options.concat([{ value: "user_all", label: "User All" }]);
  }

  const onChangeHandle = (selectedOption: Option<string>, initial = false) => {
    params.set("mode", selectedOption.value);
    if (initial) {
      history.replace({
        search: params.toString(),
      });
    } else {
      history.push({
        search: params.toString(),
      });
    }
  };

  const params = new URLSearchParams(location.search);
  const selectedMode = params.get("mode");

  useEffect(() => {
    if (selectedMode == null) {
      const defaultMode = includeAuto ? "latest" : "user_latest";
      onChangeHandle(
        options.find((o) => o.value === defaultMode),
        true,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includeAuto, options, selectedMode]);

  return (
    <Fragment>
      <Select
        onChange={(e: Option<string>) => onChangeHandle(e)}
        options={options}
        value={options.find((o) => o.value === selectedMode)}
      />
    </Fragment>
  );
};

export default withRouter(SelectMode);
