import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";

import Api from "app/js/api";
import { useLoadedData, useSafeState, useTitle } from "app/js/hooks";
import { DatasetView, DatasetViewFrame, Option } from "app/js/types";

import Button from "app/components/Buttons/Button";
import { Card, CardImage } from "app/components/Card";
import CardList from "app/components/CardList/CardList";
import FormComponent from "app/components/FormComponent/FormComponent";
import Loading from "app/components/Loading/Loading";
import Paginator from "app/components/Paginator/Paginator";
import TaskStatus from "app/components/TaskStatus/TaskStatus";

import styles from "./Download.scss";

type Qualification = "qualified" | "unqualified" | "any";

interface DatasetViewDownloadProps {
  datasetView: DatasetView;
}

export default function DatasetViewDownload({
  datasetView,
}: DatasetViewDownloadProps): React.ReactElement {
  useTitle(`MoonVision - Dataset View ${datasetView.name} - Download`);

  const datasetViewId = datasetView.id || "overview";

  const location = useLocation();
  const history = useHistory();

  const mode = "user_latest";
  const qualificationOptions = [
    { value: "qualified", label: "Qualified" },
    { value: "unqualified", label: "Unqualified" },
    { value: "any", label: "Any latest" },
  ];
  const [qualification, setQualification] = useSafeState<Qualification>(
    "qualified",
  );
  let isQualified = null;
  if (qualification === "qualified") {
    isQualified = true;
  } else if (qualification === "unqualified") {
    isQualified = false;
  }

  const [downloadTask, setDownloadTask] = useSafeState(null);
  const [downloadLink, setDownloadLink] = useSafeState(undefined);
  const resetDownload = React.useCallback(() => {
    setDownloadLink(undefined);
  }, [setDownloadLink]);

  const PAGE_LENGTH = 10;
  const params = new URLSearchParams(location.search);
  const paramsPage = params.get("page");
  const offset =
    paramsPage === null ? 0 : (parseInt(paramsPage) - 1) * PAGE_LENGTH;

  const loadImageVersions = React.useCallback(
    async (setValue, setCount) => {
      const response = await Api.datasetView(datasetViewId).frames({
        mode: mode === "user_latest" ? "any_user_latest" : mode,
        qualified: isQualified,
        skip_empty: false,
        include_unlabelled_images: false,
        limit: PAGE_LENGTH,
        offset: offset,
        with_entities: true,
      });
      setValue(response.data.results);
      setCount(response.data.count);
    },
    [datasetViewId, isQualified, offset],
  );
  const { value: frames, loading, count } = useLoadedData<DatasetViewFrame[]>(
    [],
    loadImageVersions,
  );

  const [
    archiveGenerationInProgress,
    setArchiveGenerationInProgress,
  ] = useSafeState<boolean>(false);

  const downloadTaskFinished = React.useCallback(
    (data) => {
      setDownloadLink(data.result);
      setArchiveGenerationInProgress(false);
    },
    [setArchiveGenerationInProgress, setDownloadLink],
  );

  const downloadTaskFailed = React.useCallback(() => {
    setArchiveGenerationInProgress(false);
  }, [setArchiveGenerationInProgress]);

  const onGenerateDatasetDownload = React.useCallback(async () => {
    setArchiveGenerationInProgress(true);
    setDownloadLink(undefined);
    try {
      const response = await Api.datasetView(datasetViewId).download({
        mode: mode,
        qualified: isQualified,
      });
      setDownloadTask(response.data.results.task);
    } catch (error) {
      console.error(error);
      setArchiveGenerationInProgress(false);
    }
  }, [
    datasetViewId,
    isQualified,
    setArchiveGenerationInProgress,
    setDownloadLink,
    setDownloadTask,
  ]);

  const resetOffset = () => {
    // Reset to the first page on any filter change to avoid requesting pages that exceed maximum
    params.set("page", "1");
  };

  const updateHistory = () => {
    history.push({
      ...location,
      search: params.toString(),
    });
  };

  return (
    <div style={{ padding: "0 25px 0 25px" }}>
      <h3>Download Dataset</h3>
      <p>
        Generate a zip file with all of the images and entities in this dataset.
        After the zip file is created, you will be able to download it below.
      </p>
      <div style={{ padding: "25px" }}>
        <FormComponent>
          <label>Qualification</label>
          <Select
            options={qualificationOptions}
            defaultValue={
              qualificationOptions.filter(
                (option) => option.value === qualification,
              )[0]
            }
            onChange={(e: Option<Qualification>) => {
              setQualification(e ? e.value : null);
              resetDownload();
              resetOffset();
              updateHistory();
            }}
          />
        </FormComponent>
      </div>
      <Button
        onClick={onGenerateDatasetDownload}
        big
        className={styles.buttonAlign}
        disabled={archiveGenerationInProgress}
      >
        Generate
      </Button>
      {downloadLink && (
        <Button
          onClick={() => window.open(downloadLink)}
          big
          className={styles.buttonAlign}
          disabled={archiveGenerationInProgress}
        >
          Download
        </Button>
      )}
      <TaskStatus
        rawTask={downloadTask}
        statusUpdate={downloadTaskFinished}
        handleError={downloadTaskFailed}
      />
      <div className={styles.downloadPreviewContainer}>
        <h2>Download Preview</h2>
        <div>
          {loading ? (
            <Loading />
          ) : (
            <React.Fragment>
              {frames.length > 0 ? (
                <React.Fragment>
                  {count > PAGE_LENGTH && (
                    <Paginator
                      count={count}
                      offset={offset}
                      pageLength={PAGE_LENGTH}
                      disabled={loading}
                    />
                  )}
                  <CardList>
                    {frames.map((frame) => (
                      <Card
                        className={styles.card}
                        key={`${frame.id} - ${frame.job_name}`}
                      >
                        <CardImage frame={frame} withWrapper={false} />
                      </Card>
                    ))}
                  </CardList>
                </React.Fragment>
              ) : (
                <React.Fragment>Nothing to Download</React.Fragment>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
}
