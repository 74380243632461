import React from "react";
import Api from "app/js/api";
import { RouteComponentProps } from "react-router";
import Header from "app/components/Header/Header";
import Loading from "app/components/Loading/Loading";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";
import AppInstancesTable from "app/pages/AppConfigs/AppInstances/AppInstancesTable";
import { useSafeState } from "app/js/hooks";
import { AppInstance, AppInstanceData } from "app/pages/AppConfigs/types";
import { Error } from "app/components/ErrorMessage/types";

const useAppInstanceData = (): AppInstanceData => {
  const [loading, setLoading] = useSafeState<boolean>(false);
  const [appInstanceData, setAppInstanceData] = useSafeState<{
    [id: string]: AppInstance[];
  }>({});
  const [moonboxAppNames, setMoonboxAppNames] = useSafeState<string[]>([]);
  const [error, setError] = useSafeState(null);

  const reloadAppInstanceData = React.useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const [moonboxAppResponse, appInstanceResponse] = await Promise.all([
        Api.moonboxApplication().all({
          limit: 1000000,
        }),
        Api.applicationInstance().all({
          limit: 1000000,
        }),
      ]);

      setMoonboxAppNames(
        moonboxAppResponse.data.results.map(({ name }) => name).sort(),
      );

      const _appInstanceData = {};
      appInstanceResponse.data.results.forEach((appInstance) => {
        _appInstanceData[appInstance.application] = [
          ...(_appInstanceData[appInstance.application] || []),
          appInstance,
        ];
      });
      setAppInstanceData(_appInstanceData);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [setAppInstanceData, setError, setLoading, setMoonboxAppNames]);

  React.useEffect(() => {
    reloadAppInstanceData();
  }, [reloadAppInstanceData]);

  return {
    loading,
    moonboxAppNames,
    appInstanceData,
    reloadAppInstanceData,
    error: error as Error,
  };
};

const AppInstances: React.FC<RouteComponentProps> = ({ match }) => {
  const {
    appInstanceData,
    error,
    loading,
    moonboxAppNames,
  } = useAppInstanceData();

  const hasAppInstanceData = moonboxAppNames && moonboxAppNames.length > 0;

  return (
    <>
      <Header>
        <h1>App Configs</h1>
      </Header>
      {error && <ErrorMessage error={error} />}
      {loading && <Loading />}
      {!loading &&
        hasAppInstanceData &&
        moonboxAppNames.map((moonboxAppName) => {
          const createInstanceUrl = `${
            match.path
          }/create-instance/${encodeURIComponent(moonboxAppName)}`;
          return (
            <AppInstancesTable
              key={moonboxAppName}
              appInstances={appInstanceData[moonboxAppName] || []}
              createInstanceUrl={createInstanceUrl}
              moonboxAppName={moonboxAppName}
            />
          );
        })}
      {!loading && !hasAppInstanceData && (
        <div style={{ margin: "25px", maxWidth: "620px" }}>No Data</div>
      )}
    </>
  );
};

export default AppInstances;
