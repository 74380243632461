import React from "react";
import classNames from "classnames";

import { default as Form, IChangeEvent, ISubmitEvent } from "@rjsf/core";
import { useSafeState } from "app/js/hooks";

import fields from "app/pages/AppConfigs/EditConfigForm/fields";
import widgets from "app/pages/AppConfigs/EditConfigForm/widgets";

import cloudRecognitionUiSchema from "app/pages/AppConfigs/EditConfigForm/Cloud Recognition.json";
import dishtrackerAppUiSchema from "app/pages/AppConfigs/EditConfigForm/Dishtracker App.json";
import styles from "app/pages/AppConfigs/EditConfigForm/EditConfigForm.scss";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";

const UI_SCHEMA_PATHS = {
  "Cloud Recognition": cloudRecognitionUiSchema,
  "Dishtracker App": dishtrackerAppUiSchema,
};

const customFormats = {
  path: "^(.+)\\/([^\\/]+)$",
  "/api/datasets/": (value) => value >= 1,
  "/api/detectors/": (value) => {
    if ("file_url" in value) {
      return !!value.path;
    }
    if ("id" in value) {
      return value.id >= 1;
    }
    return false;
  },
  "/api/labelingjobs/": (value) => value >= 1,
  "/api/streams/":
    "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$",
};

interface EditConfigFormProps {
  jsonSchema?: Record<string, any>;
  application?: string;
  value: string;
  onChange: (value, event, error) => void;
  submitRef: React.MutableRefObject<() => void>;
}

const EditConfigForm: React.FC<EditConfigFormProps> = ({
  application,
  jsonSchema,
  value,
  onChange,
  submitRef,
}) => {
  const submitButtonRef = React.useRef<HTMLInputElement>();
  // JSONSchemaForm errors when uiSchema is null. undefined is fine though
  const [uiSchema, setUiSchema] = useSafeState<Record<string, unknown>>(
    undefined,
  );
  React.useEffect(() => {
    if (application && UI_SCHEMA_PATHS[application]) {
      setUiSchema(UI_SCHEMA_PATHS[application]);
    } else {
      setUiSchema(undefined);
    }
  }, [application, setUiSchema]);

  const _onChange = React.useCallback(
    (event: IChangeEvent, error) => {
      onChange(JSON.stringify(event.formData, null, 4), event, error);
    },
    [onChange],
  );

  const onSubmit = (
    e: ISubmitEvent<any>,
    nativeEvent: React.FormEvent<HTMLFormElement>,
  ) => {
    nativeEvent.preventDefault();
  };

  let _formData = {};
  let _formDataInvalid = false;
  if (value) {
    try {
      _formData = JSON.parse(value);
    } catch {
      _formDataInvalid = true;
    }
  }

  if (submitRef) {
    submitRef.current = () => {
      if (submitButtonRef.current) {
        submitButtonRef.current.click();
      }
    };
  }

  if (!jsonSchema) {
    return null;
  }

  if (_formDataInvalid) {
    return (
      <ErrorMessage
        error={{
          message:
            "Your configuration is invalid. Switch back to Code mode and fix it.",
        }}
      />
    );
  }

  return (
    <div className={classNames(styles.container, styles.bootstrap)}>
      <Form
        className={styles.form}
        fields={fields}
        widgets={widgets}
        formData={_formData}
        schema={jsonSchema}
        uiSchema={uiSchema}
        onChange={_onChange}
        onSubmit={onSubmit}
        customFormats={customFormats}
      >
        <input
          type="submit"
          style={{ display: "none" }}
          ref={submitButtonRef}
        />
      </Form>
    </div>
  );
};

export default EditConfigForm;
