import React, { useEffect, Fragment } from "react";
import moment from "moment";

import { useChanged, useSafeState } from "app/js/hooks";
import { LabelVersion, LabelVersionMode } from "app/js/types";

import Api from "app/js/api";
import { isLeftClick } from "app/js/util";
import { Card, CardDetails } from "app/components/Card";
import CardList from "app/components/CardList/CardList";
import FormComponent from "app/components/FormComponent/FormComponent";
import GalleryModal from "app/components/Modal/GalleryModal";
import Loading from "app/components/Loading/Loading";
import Paginator from "app/components/Paginator/Paginator";
import SelectUser from "app/components/Selects/SelectUser";
import SelectMode from "app/components/Selects/SelectMode";
import styles from "./LabellingJobLabelledImages.scss";
import PageSizeChanger, {
  readPageLengthCookie,
} from "app/components/PageSizeChanger/PageSizeChanger";
import { CardImage } from "app/components/Card";
import { Button } from "app/components/Buttons";
import { Error } from "app/components/ErrorMessage/types";
import ErrorMessage from "app/components/ErrorMessage/ErrorMessage";

export default function LabellingJobLabelledImages({
  match,
  location,
  history,
}) {
  const jobId = match.params.labellingJobId;
  const params = React.useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ]);
  const [imageVersions, setImageVersions] = useSafeState<LabelVersion[]>([]);
  const [loading, setLoading] = useSafeState(false);
  const [_error, setError] = useSafeState(null);
  const PAGE_LENGTH_COOKIE = "moonvision-labelledImagesPageLength";
  const [pageLength, setPageLength] = useSafeState<number>(
    readPageLengthCookie(PAGE_LENGTH_COOKIE),
  );
  const [offset, setOffset] = useSafeState<number>(0);
  const [count, setCount] = useSafeState<number>(0);
  const [bigFrameId, setBigFrameId] = useSafeState<number | null>(null);

  const mode = params.get("mode") as LabelVersionMode;
  const [selectedUser, setSelectedUser] = useSafeState<string>(
    params.get("user"),
  );
  const [before, setBefore] = useSafeState(
    params.get("before") ? params.get("before") : null,
  );
  const [after, setAfter] = useSafeState(
    params.get("after") ? params.get("after") : null,
  );

  const [imageSkipMode, setImageSkipMode] = useSafeState<boolean>(false);
  const [selectedImages, setSelectedImages] = useSafeState<number[]>([]);
  const [bulkOperationInProcess, setBulkOperationInProcess] = useSafeState<
    boolean
  >(false);
  const [bulkOperationError, setBulkOperationError] = useSafeState<Error>(null);

  useEffect(() => {
    setBefore(params.get("before") ? params.get("before") : null);
    setAfter(params.get("after") ? params.get("after") : null);
    setSelectedUser(params.get("user"));
  }, [params, setAfter, setBefore, setSelectedUser]);

  const afterChanged = useChanged(after);
  const beforeChanged = useChanged(before);
  const modeChanged = useChanged(mode);
  const offsetChanged = useChanged(offset);
  const userChanged = useChanged(selectedUser);
  const paramsChanged =
    afterChanged ||
    beforeChanged ||
    modeChanged ||
    offsetChanged ||
    userChanged;
  React.useEffect(() => {
    if (imageSkipMode && paramsChanged) {
      alert("Page parameters changed, multi-select mode will be disabled.");
      setSelectedImages([]);
      setImageSkipMode(false);
    }
  }, [imageSkipMode, paramsChanged, setImageSkipMode, setSelectedImages]);

  const loadImageVersions = React.useCallback(async () => {
    setLoading(true);
    if (mode == null) {
      return;
    }
    try {
      const filters = {
        job_ids: [parseInt(jobId)],
        created_before: before ? before : null,
        created_after: after ? after : null,
        mode: mode,
        include_entities: true,
        order_by: "-id",
        limit: pageLength,
        offset,
      };
      if (selectedUser != null) {
        filters["user_ids"] = JSON.stringify([parseInt(selectedUser)]);
      }
      const response = await Api.labelversion().all(filters);
      setImageVersions(response.data.results);
      setCount(response.data.count);
      setError(null);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [
    jobId,
    selectedUser,
    before,
    after,
    offset,
    mode,
    pageLength,
    setLoading,
    setImageVersions,
    setCount,
    setError,
  ]);

  useEffect(() => {
    loadImageVersions();
  }, [loadImageVersions]);

  const onRangeUpdate = (newRange) => {
    newRange = { ...{ before, after }, ...newRange };
    if (newRange.before && newRange.before !== "") {
      params.set("before", moment(newRange.before).format());
    } else {
      params.delete("before");
    }
    if (newRange.after && newRange.after !== "") {
      params.set("after", moment(newRange.after).format());
    } else {
      params.delete("after");
    }
    history.push({
      location,
      search: params.toString(),
    });
  };

  const onUserUpdate = (user) => {
    if (user != null) {
      params.set("user", user.id);
    } else {
      params.delete("user");
    }
    history.push({
      location,
      search: params.toString(),
    });
  };

  const handleSkipModeClick = async () => {
    if (imageSkipMode) {
      if (selectedImages.length > 0) {
        setBulkOperationInProcess(true);
        setBulkOperationError(null);
        try {
          await Api.job(jobId).framesToLabel().bulkSkip(selectedImages);
          setSelectedImages([]);
          setImageSkipMode(false);
          loadImageVersions();
        } catch (e) {
          console.log(e);
          setBulkOperationError({ message: "Operation failed" });
        }
        setBulkOperationInProcess(false);
      } else {
        // Nothing to save, just disable the mode
        setBulkOperationError(null);
        setImageSkipMode(false);
      }
    } else {
      setImageSkipMode(true);
    }
  };
  const onImageSelect = (frameId: number, isSelected: boolean) => {
    if (isSelected) {
      setSelectedImages([...selectedImages, frameId]);
    } else {
      setSelectedImages(selectedImages.filter((f) => f !== frameId));
    }
  };

  return (
    <div style={{ height: "100%", overflowY: "hidden", display: "grid" }}>
      <div style={{ padding: "25px", overflowY: "auto" }}>
        <FormComponent>
          <label>User:</label>
          <SelectUser value={selectedUser} setUser={onUserUpdate} />

          <label>Versions:</label>
          <SelectMode includeUserAll={true} includeAuto={false} />

          <label>Date from:</label>
          <input
            type="datetime-local"
            value={
              after ? moment(after).format(moment.HTML5_FMT.DATETIME_LOCAL) : ""
            }
            onChange={(e) => {
              onRangeUpdate({ after: e.target.value });
            }}
          />

          <label>Date to:</label>
          <input
            type="datetime-local"
            value={
              before
                ? moment(before).format(moment.HTML5_FMT.DATETIME_LOCAL)
                : ""
            }
            onChange={(e) => {
              onRangeUpdate({ before: e.target.value });
            }}
          />
        </FormComponent>

        {loading && <Loading />}
        {!loading && imageVersions.length > 0 ? (
          <Fragment>
            {count > pageLength && (
              <Paginator
                onChange={setOffset}
                count={count}
                offset={offset}
                pageLength={pageLength}
                disabled={loading}
                supportOpeningInNewTab={true}
              />
            )}

            <CardList>
              {imageVersions &&
                imageVersions.map((version) => (
                  <Card
                    className={styles.card}
                    key={version.id}
                    url={`/labelling-jobs/${jobId}/frame/${
                      version.frame.id
                    }/?label_version=${
                      version.id
                    }&redirect=/labelling-jobs/${jobId}/labelled-images&redirect_parameters=page=${
                      offset / pageLength + 1
                    }`}
                    media={<CardImage labelVersion={version} />}
                    showCheckbox={imageSkipMode}
                    checkboxValue={selectedImages.includes(version.frame.id)}
                    onCheckboxClick={(value) =>
                      onImageSelect(version.frame.id, value)
                    }
                    onClick={(e) => {
                      // TODO: find, how to avoid creating anonymous callback
                      //       One option might be to pass id into the Card and use it as a parameter there
                      if (isLeftClick(e)) {
                        e.preventDefault();
                        setBigFrameId(version.frame.id);
                      }
                    }}
                  >
                    <CardDetails>
                      Edited {moment(version.create_time).format("LLL")}
                      <br />
                      Frame ID: {version.frame.id}
                      <br />
                      Camera: {version.frame.stream_name || "-"}
                    </CardDetails>
                  </Card>
                ))}
            </CardList>
            <PageSizeChanger
              pageLength={pageLength}
              setPageLength={setPageLength}
              count={count}
              cookieName={PAGE_LENGTH_COOKIE}
            />
            <div className={styles.buttonsContainer}>
              <div className={styles.buttons}>
                <Button
                  onClick={handleSkipModeClick}
                  disabled={bulkOperationInProcess}
                >
                  {imageSkipMode
                    ? "Skip selected images"
                    : "Select images to skip"}
                </Button>
              </div>
              {bulkOperationError && (
                <ErrorMessage error={bulkOperationError} />
              )}
            </div>
          </Fragment>
        ) : (
          !loading && <Fragment>No Versions Found</Fragment>
        )}
        <GalleryModal
          frames={imageVersions.map((v) => ({
            id: v.frame.id,
            image: {
              image: v.frame.image_path,
              height: v.frame.image_height,
              width: v.frame.image_width,
            },
            entities: v.entities,
          }))}
          activeFrame={bigFrameId}
          setActiveFrame={setBigFrameId}
          loading={loading}
          pageLength={pageLength}
          count={count}
          drawEntities={true}
        />
      </div>
    </div>
  );
}
